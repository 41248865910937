







import { defineComponent, watch } from 'vue-demi';
import { useOnline } from '@vueuse/core';
import { useRouter } from '@/router';

export default defineComponent({
  setup() {
    const isOnline = useOnline();
    const router = useRouter();

    watch(isOnline, (value) => {
      if (value) router.replace('/');
    });
  },
});
